<script>
import axios from "axios";
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import appConfig from "@/app.config";

/**
 * Login-2 component
 */
export default {
  page: {
    title: "Se connecter",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      performingRequest: false,
      loading: false,
      logo: "",
      submitted: false,
      authForm: {
        username: "",
        password: "",
      },
    };
  },

  validations: {
    authForm: {
      username: { required },
      password: { required },
    },
  },

  computed: {},
  methods: {
    ...mapActions({
      signIn: "apiAuth/signIn",
    }),
    loadingStarted: function() {
      this.performingRequest = true;
    },

    loadingEnded: function() {
      this.performingRequest = false;
    },

    testRequest() {
      this.performingRequest = true;
      this.performSigIn();
    },
    async performSigIn() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.authForm.userAgent = this.$browserDetect.meta;
        var loader = this.$loading.show();
        let login = await this.signIn(this.authForm); /// to record browser infos
        if (login.status) {
          loader.hide();
          this.$toast.error(login.msg);
        }
      }
    },

    readCompanyLogo() {
      var macaddress = require('macaddress');
      // console.log(JSON.stringify(macaddress.networkInterfaces(), null, 2));
      var path = "";
      this.$http
        .post("/system/settings/getCompanyLogo")
        .then((res) => {
          this.logo = res.data.original.path;
        })
        .catch((error) => {
          console.log(error.message);
        })
        .finally(() => {});
      return path;
    },
  },
  mounted() {
    this.readCompanyLogo();
    document.body.classList.add("auth-body-bg");
  },
};
</script>

<template>
  <div>
    <div class="container-fluid p-0">
      <div class="row g-0">
        <div class="col-xl-8">
          <div class="auth-full-bg pt-lg-5 p-4">
            <div class="w-100">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
        <!-- end col -->

        <div class="col-xl-4">
          <div class="row">
            <div class="col-md-6">
              <!-- <div class="mb-2 mb-md-2"> -->
              <router-link to="/" class="d-block auth-logo">
                <img
                  src="@/assets/images/camplus_logo.png"
                  alt=""
                  height="80"
                  class="auth-logo-dark"
                />
                <img
                  src="@/assets/images/camplus_logo.png"
                  alt=""
                  height="18"
                  class="auth-logo-light"
                />
              </router-link>
              <!-- </div> -->
            </div>

            <div class="col-md-6">
              <div class="mb-4 mb-md-5">
                <router-link to="/" class="d-block auth-logo">
                  <img :src="logo" alt="" height="80" class="auth-logo-dark" />
                </router-link>
              </div>
            </div>
          </div>

          <div class="p-4">
            <div class="w-100">
              <div class="d-flex flex-column h-100">
                <div class="my-auto">
                  <div>
                    <h5 class="text-primary">Bienvenu !</h5>
                    <p class="text-muted">
                      Veuillez vous connecter pour continuer vers
                      <b>Camplus</b>.
                    </p>
                  </div>

                  <div class="mt-4">
                    <form
                      class="form-horizontal"
                      @submit.prevent="performSigIn"
                    >
                      <div class="form-group">
                        <label for="username">Nom d'utilisateur</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="authForm.username"
                          :class="{
                            'is-invalid':
                              submitted && $v.authForm.username.$error,
                          }"
                          id="username"
                        />
                        <div
                          v-if="submitted && $v.authForm.username.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.authForm.username.required"
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="userpassword">Mot de passe</label>
                        <input
                          type="password"
                          class="form-control"
                          v-model="authForm.password"
                          :class="{
                            'is-invalid':
                              submitted && $v.authForm.password.$error,
                          }"
                          id="userpassword"
                        />
                        <div
                          v-if="submitted && $v.authForm.password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.authForm.password.required"
                            >Champ obligatoire.</span
                          >
                          <br />
                        </div>
                      </div>

                      <div class="mt-3">
                        <button
                          class="btn btn-primary btn-block"
                          :disabled="performingRequest"
                          type="submit"
                        >
                          Se connecter
                        </button>
                      </div>
                    </form>
                  </div>
                </div>

                <div class="mt-5 text-center">
                  <p class="mb-0">
                    © {{ new Date().getFullYear() }}
                    <b>
                      <img
                        src="@/assets/images/camplus_logo.png"
                        alt=""
                        height="30"
                        class="auth-logo-dark"
                      />
                    </b>.
                    <!-- . développé et maintenu Par -->
                    <!-- <i class="mdi mdi-heart text-danger"></i> Par -->
                    <a href="https://azimutbs.dz" target="_blank"
                      >AZIMUT BUSINESS SOLUTIONS</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end col -->
      </div>
      <!-- end row -->
    </div>
    <!-- end container-fluid -->
  </div>
</template>

<style lang="scss" scoped>
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}
</style>
