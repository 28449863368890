var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row g-0" }, [
        _vm._m(0),
        _c("div", { staticClass: "col-xl-4" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-6" },
              [
                _c(
                  "router-link",
                  { staticClass: "d-block auth-logo", attrs: { to: "/" } },
                  [
                    _c("img", {
                      staticClass: "auth-logo-dark",
                      attrs: {
                        src: require("@/assets/images/camplus_logo.png"),
                        alt: "",
                        height: "80"
                      }
                    }),
                    _c("img", {
                      staticClass: "auth-logo-light",
                      attrs: {
                        src: require("@/assets/images/camplus_logo.png"),
                        alt: "",
                        height: "18"
                      }
                    })
                  ]
                )
              ],
              1
            ),
            _c("div", { staticClass: "col-md-6" }, [
              _c(
                "div",
                { staticClass: "mb-4 mb-md-5" },
                [
                  _c(
                    "router-link",
                    { staticClass: "d-block auth-logo", attrs: { to: "/" } },
                    [
                      _c("img", {
                        staticClass: "auth-logo-dark",
                        attrs: { src: _vm.logo, alt: "", height: "80" }
                      })
                    ]
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "p-4" }, [
            _c("div", { staticClass: "w-100" }, [
              _c("div", { staticClass: "d-flex flex-column h-100" }, [
                _c("div", { staticClass: "my-auto" }, [
                  _vm._m(1),
                  _c("div", { staticClass: "mt-4" }, [
                    _c(
                      "form",
                      {
                        staticClass: "form-horizontal",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return _vm.performSigIn($event)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "username" } }, [
                            _vm._v("Nom d'utilisateur")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.authForm.username,
                                expression: "authForm.username"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.submitted && _vm.$v.authForm.username.$error
                            },
                            attrs: { type: "text", id: "username" },
                            domProps: { value: _vm.authForm.username },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.authForm,
                                  "username",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.submitted && _vm.$v.authForm.username.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.authForm.username.required
                                  ? _c("span", [_vm._v("Champ obligatoire.")])
                                  : _vm._e(),
                                _c("br")
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "userpassword" } }, [
                            _vm._v("Mot de passe")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.authForm.password,
                                expression: "authForm.password"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.submitted && _vm.$v.authForm.password.$error
                            },
                            attrs: { type: "password", id: "userpassword" },
                            domProps: { value: _vm.authForm.password },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.authForm,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.submitted && _vm.$v.authForm.password.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.authForm.password.required
                                  ? _c("span", [_vm._v("Champ obligatoire.")])
                                  : _vm._e(),
                                _c("br")
                              ])
                            : _vm._e()
                        ]),
                        _c("div", { staticClass: "mt-3" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary btn-block",
                              attrs: {
                                disabled: _vm.performingRequest,
                                type: "submit"
                              }
                            },
                            [_vm._v(" Se connecter ")]
                          )
                        ])
                      ]
                    )
                  ])
                ]),
                _c("div", { staticClass: "mt-5 text-center" }, [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(" © " + _vm._s(new Date().getFullYear()) + " "),
                    _vm._m(2),
                    _vm._v(". "),
                    _c(
                      "a",
                      {
                        attrs: { href: "https://azimutbs.dz", target: "_blank" }
                      },
                      [_vm._v("AZIMUT BUSINESS SOLUTIONS")]
                    )
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xl-8" }, [
      _c("div", { staticClass: "auth-full-bg pt-lg-5 p-4" }, [
        _c("div", { staticClass: "w-100" }, [
          _c("div", { staticClass: "bg-overlay" })
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", { staticClass: "text-primary" }, [_vm._v("Bienvenu !")]),
      _c("p", { staticClass: "text-muted" }, [
        _vm._v(" Veuillez vous connecter pour continuer vers "),
        _c("b", [_vm._v("Camplus")]),
        _vm._v(". ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("b", [
      _c("img", {
        staticClass: "auth-logo-dark",
        attrs: {
          src: require("@/assets/images/camplus_logo.png"),
          alt: "",
          height: "30"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }